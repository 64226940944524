import LibIcons from './icon-lib-maker'
import type { IconLibMaker } from './icon-lib-maker'
import { IconBulb } from './IconBulb'
import { IconCheckFill } from './IconCheckFill'
import { IconMarket } from './IconMarket'
import { IconClose } from './IconClose'
import { IconChevronRight } from './IconChevronRight'

export default {
  ...LibIcons,
  'icon-bulb': IconBulb,
  'icon-check-fill': IconCheckFill,
  'icon-chevron-right': IconChevronRight,
  'icon-close': IconClose,
  'icon-market': IconMarket
} as IconLibMaker