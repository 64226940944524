import { toRgb } from './utils/colors'

export const styles = `
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"],
input[type="password"],
input[type="text"] {
  -moz-appearance: textfield;
  outline: 0;
  border: 0;
}
input::-moz-selection {
  color: inherit;
}
input::selection {
  color: inherit;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 20px;
}
.toolbar-app {
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  z-index: 5000;
  display: flex;
  align-items: center;
}
.topbar-divider {
  display: inline-block;
  flex: 0 0 auto;
  width: 1px;
  height: 20px;
  border-left: 1px solid currentColor;
}
.config-dropdown-prepend {
  text-align: center;
  border-bottom: 1px solid rgb(var(--topbar-border));
  padding: 15px 28px;
}
.config-dropdown .topbar-dropwdown-item.disabled .append-icon {
  --topbar-text-opacity: 1;
}
`

export const typography = `
:host {
  --topbar-font-family: "DM Sans", sans-serif;
  --topbar-font-bold: 700;
  --topbar-font-medium: 500;
  --topbar-font-normal: 400;
  --topbar-text-xs: 10px;
  --topbar-text-sm: 13px;
  --topbar-text-md: 14px;
  --topbar-text-lg: 16px;
  --topbar-text-xl: 18px;
  font-family: var(--topbar-font-family);
  font-size: var(--topbar-text-sm);
  font-style: normal;
  font-weight: 400;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-weight: var(--topbar-font-medium);
}
p {
  margin: 0;
}
b, strong {
  font-weight: var(--topbar-font-medium);
}
.text-bold {
  font-weight: var(--topbar-font-bold) !important;
}
.text-medium {
  font-weight: var(--topbar-font-medium) !important;
}
.text-normal {
  font-weight: var(--topbar-font-normal) !important;
}
.text-xs {
  font-size: var(--topbar-text-xs) !important;
}
.text-sm {
  font-size: var(--topbar-text-sm) !important;
}
.text-md {
  font-size: var(--topbar-text-md) !important;
}
.text-lg {
  font-size: var(--topbar-text-lg) !important;
}
.text-xl {
  font-size: var(--topbar-text-xl) !important;
}
.text-underline {
  text-decoration: underline;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
`

export const flexUtilities = `
.flex {
  display: flex !important;
}
.inline-flex {
  display: inline-flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-space-between {
  justify-content: space-between !important;
}
.align-start {
  align-items: flex-start !important;
}
.align-center {
  align-items: center !important;
}
.align-end {
  align-items: flex-end !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-shrink-1 {
  flex-shrink: 1 !important;
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}
`

export const colors = ({ primary, secondary } : { primary: string, secondary: string }) => `
:host {
  --topbar-primary: ${toRgb(primary).join(',')};
  --topbar-secondary: ${toRgb(secondary).join(',')};
  --topbar-black: 0, 0, 0;
  --topbar-black-grey: 51, 51, 51;
  --topbar-dark-grey: 115, 115, 115;
  --topbar-soft-grey: 226, 226, 226;
  --topbar-light-grey: 250, 250, 250;
  --topbar-white: 255, 255, 255;
  --topbar-success: 117, 184, 78;
  --topbar-error: 231, 45, 81;
}
.theme-light {
  --topbar-background: var(--topbar-white);
  --topbar-text: var(--topbar-black-grey);
  --topbar-border: var(--topbar-soft-grey);
  --topbar-disabled: var(--topbar-soft-grey);
}
.theme-dark {
  --topbar-background: ${toRgb('#363740').join(',')};
  --topbar-text: var(--topbar-white);
  --topbar-border: var(--topbar-dark-grey);
  --topbar-disabled: var(--topbar-dark-grey);
}

.text-black {
  color: rgba(var(--topbar-black), var(--topbar-text-opacity, 1)) !important;
}
.text-black-grey {
  color: rgba(var(--topbar-black-grey), var(--topbar-text-opacity, 1)) !important;
}
.text-dark-grey {
  color: rgba(var(--topbar-dark-grey), var(--topbar-text-opacity, 1)) !important;
}
.text-soft-grey {
  color: rgba(var(--topbar-soft-grey), var(--topbar-text-opacity, 1)) !important;
}
.text-light-grey {
  color: rgba(var(--topbar-light-grey), var(--topbar-text-opacity, 1)) !important;
}
.text-white {
  color: rgba(var(--topbar-white), var(--topbar-text-opacity, 1)) !important;
}
.text-transparent {
  color: transparent !important;
}
.text-inherit {
  color: inherit !important;
}
.text-current {
  color: currentColor !important;
}
.text-background {
  color: rgba(var(--topbar-background), var(--topbar-text-opacity, 1)) !important;
}
.text-border {
  color: rgba(var(--topbar-border), var(--topbar-text-opacity, 1)) !important;
}
.text-disabled {
  color: rgba(var(--topbar-disabled), var(--topbar-text-opacity, 1)) !important;
}
.text-primary {
  color: rgba(var(--topbar-primary), var(--topbar-text-opacity, 1)) !important;
}
.text-secondary {
  color: rgba(var(--topbar-secondary), var(--topbar-text-opacity, 1)) !important;
}
.text-success {
  color: rgba(var(--topbar-success), var(--topbar-text-opacity, 1)) !important;
}
.text-error {
  color: rgba(var(--topbar-error), var(--topbar-text-opacity, 1)) !important;
}
.text-base {
  color: rgba(var(--topbar-text), var(--topbar-text-opacity, 1)) !important;
}

.bg-black {
  background-color: rgba(var(--topbar-black), var(--topbar-bg-opacity, 1)) !important;
}
.bg-black-grey {
  background-color: rgba(var(--topbar-black-grey), var(--topbar-bg-opacity, 1)) !important;
}
.bg-dark-grey {
  background-color: rgba(var(--topbar-dark-grey), var(--topbar-bg-opacity, 1)) !important;
}
.bg-soft-grey {
  background-color: rgba(var(--topbar-soft-grey), var(--topbar-bg-opacity, 1)) !important;
}
.bg-light-grey {
  background-color: rgba(var(--topbar-light-grey), var(--topbar-bg-opacity, 1)) !important;
}
.bg-white {
  background-color: rgba(var(--topbar-white), var(--topbar-bg-opacity, 1)) !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.bg-inherit {
  background-color: inherit !important;
}
.bg-current {
  background-color: currentColor !important;
}
.bg-background {
  background-color: rgba(var(--topbar-background), var(--topbar-bg-opacity, 1)) !important;
}
.bg-border {
  background-color: rgba(var(--topbar-border), var(--topbar-bg-opacity, 1)) !important;
}
.bg-disabled {
  background-color: rgba(var(--topbar-disabled), var(--topbar-bg-opacity, 1)) !important;
}
.bg-primary {
  background-color: rgba(var(--topbar-primary), var(--topbar-bg-opacity, 1)) !important;
}
.bg-secondary {
  background-color: rgba(var(--topbar-secondary), var(--topbar-bg-opacity, 1)) !important;
}
.bg-success {
  background-color: rgba(var(--topbar-success), var(--topbar-bg-opacity, 1)) !important;
}
.bg-error {
  background-color: rgba(var(--topbar-error), var(--topbar-bg-opacity, 1)) !important;
}
.bg-base {
  background-color: rgba(var(--topbar-text), var(--topbar-bg-opacity, 1)) !important;
}

.border-black {
  border-color: rgba(var(--topbar-black), var(--topbar-border-opacity, 1)) !important;
}
.border-black-grey {
  border-color: rgba(var(--topbar-black-grey), var(--topbar-border-opacity, 1)) !important;
}
.border-dark-grey {
  border-color: rgba(var(--topbar-dark-grey), var(--topbar-border-opacity, 1)) !important;
}
.border-soft-grey {
  border-color: rgba(var(--topbar-soft-grey), var(--topbar-border-opacity, 1)) !important;
}
.border-light-grey {
  border-color: rgba(var(--topbar-light-grey), var(--topbar-border-opacity, 1)) !important;
}
.border-white {
  border-color: rgba(var(--topbar-white), var(--topbar-border-opacity, 1)) !important;
}
.border-transparent {
  border-color: transparent !important;
}
.border-inherit {
  border-color: inherit !important;
}
.border-current {
  border-color: currentColor !important;
}
.border-background {
  border-color: rgba(var(--topbar-background), var(--topbar-border-opacity, 1)) !important;
}
.border-border {
  border-color: rgba(var(--topbar-border), var(--topbar-border-opacity, 1)) !important;
}
.border-disabled {
  border-color: rgba(var(--topbar-disabled), var(--topbar-border-opacity, 1)) !important;
}
.border-primary {
  border-color: rgba(var(--topbar-primary), var(--topbar-border-opacity, 1)) !important;
}
.border-secondary {
  border-color: rgba(var(--topbar-secondary), var(--topbar-border-opacity, 1)) !important;
}
.bg-success {
  border-color: rgba(var(--topbar-success), var(--topbar-border-opacity, 1)) !important;
}
.border-error {
  border-color: rgba(var(--topbar-error), var(--topbar-border-opacity, 1)) !important;
}
.border-base {
  border-color: rgba(var(--topbar-text), var(--topbar-border-opacity, 1)) !important;
}
.border-0 {
  border-style: solid !important;
  border-width: 0px !important;
}
.border-1 {
  border-style: solid !important;
  border-width: 1px !important;
}
.border-2 {
  border-style: solid !important;
  border-width: 2px !important;
}
.border-top-0 {
  border-style: solid !important;
  border-top-width: 0px !important;
}
.border-top-1 {
  border-style: solid !important;
  border-top-width: 1px !important;
}
.border-top-2 {
  border-style: solid !important;
  border-top-width: 2px !important;
}
.border-bottom-0 {
  border-style: solid !important;
  border-bottom-width: 0px !important;
}
.border-bottom-1 {
  border-style: solid !important;
  border-bottom-width: 1px !important;
}
.border-bottom-2 {
  border-style: solid !important;
  border-bottom-width: 2px !important;
}

.text-lighten {
  --topbar-text-opacity: 0.1 !important;
}
.bg-lighten {
  --topbar-bg-opacity: 0.1 !important;
}
.border-lighten {
  --topbar-border-opacity: 0.1 !important;
}
`

export const spacing = `
:host {
  --topbar-spacer: 3px;
}
.m-0 {
  margin: 0px !important;
}
.m-1 {
  margin: var(--topbar-spacer) !important;
}
.m-2 {
  margin: calc(var(--topbar-spacer) * 2) !important;
}
.m-3 {
  margin: calc(var(--topbar-spacer) * 3) !important;
}
.m-4 {
  margin: calc(var(--topbar-spacer) * 4) !important;
}
.m-5 {
  margin: calc(var(--topbar-spacer) * 5) !important;
}
.m-10 {
  margin: calc(var(--topbar-spacer) * 10) !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.mx-1 {
  margin-left: var(--topbar-spacer) !important;
  margin-right: var(--topbar-spacer) !important;
}
.mx-2 {
  margin-left: calc(var(--topbar-spacer) * 2) !important;
  margin-right: calc(var(--topbar-spacer) * 2) !important;
}
.mx-3 {
  margin-left: calc(var(--topbar-spacer) * 3) !important;
  margin-right: calc(var(--topbar-spacer) * 3) !important;
}
.mx-4 {
  margin-left: calc(var(--topbar-spacer) * 4) !important;
  margin-right: calc(var(--topbar-spacer) * 4) !important;
}
.mx-5 {
  margin-left: calc(var(--topbar-spacer) * 5) !important;
  margin-right: calc(var(--topbar-spacer) * 5) !important;
}
.mx-10 {
  margin-left: calc(var(--topbar-spacer) * 10) !important;
  margin-right: calc(var(--topbar-spacer) * 10) !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.my-1 {
  margin-top: var(--topbar-spacer) !important;
  margin-bottom: var(--topbar-spacer) !important;
}
.my-2 {
  margin-top: calc(var(--topbar-spacer) * 2) !important;
  margin-bottom: calc(var(--topbar-spacer) * 2) !important;
}
.my-3 {
  margin-top: calc(var(--topbar-spacer) * 3) !important;
  margin-bottom: calc(var(--topbar-spacer) * 3) !important;
}
.my-4 {
  margin-top: calc(var(--topbar-spacer) * 4) !important;
  margin-bottom: calc(var(--topbar-spacer) * 4) !important;
}
.my-5 {
  margin-top: calc(var(--topbar-spacer) * 5) !important;
  margin-bottom: calc(var(--topbar-spacer) * 5) !important;
}
.my-10 {
  margin-top: calc(var(--topbar-spacer) * 10) !important;
  margin-bottom: calc(var(--topbar-spacer) * 10) !important;
}

.ml-auto {
  margin-left: auto !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-1 {
  margin-left: var(--topbar-spacer) !important;
}
.ml-2 {
  margin-left: calc(var(--topbar-spacer) * 2) !important;
}
.ml-3 {
  margin-left: calc(var(--topbar-spacer) * 3) !important;
}
.ml-4 {
  margin-left: calc(var(--topbar-spacer) * 4) !important;
}
.ml-5 {
  margin-left: calc(var(--topbar-spacer) * 5) !important;
}
.ml-10 {
  margin-left: calc(var(--topbar-spacer) * 10) !important;
}

.mr-auto {
  margin-right: auto !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr-1 {
  margin-right: var(--topbar-spacer) !important;
}
.mr-2 {
  margin-right: calc(var(--topbar-spacer) * 2) !important;
}
.mr-3 {
  margin-right: calc(var(--topbar-spacer) * 3) !important;
}
.mr-4 {
  margin-right: calc(var(--topbar-spacer) * 4) !important;
}
.mr-5 {
  margin-right: calc(var(--topbar-spacer) * 5) !important;
}
.mr-10 {
  margin-right: calc(var(--topbar-spacer) * 10) !important;
}

.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: var(--topbar-spacer) !important;
}
.mt-2 {
  margin-top: calc(var(--topbar-spacer) * 2) !important;
}
.mt-3 {
  margin-top: calc(var(--topbar-spacer) * 3) !important;
}
.mt-4 {
  margin-top: calc(var(--topbar-spacer) * 4) !important;
}
.mt-5 {
  margin-top: calc(var(--topbar-spacer) * 5) !important;
}
.mt-10 {
  margin-top: calc(var(--topbar-spacer) * 10) !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: var(--topbar-spacer) !important;
}
.mb-2 {
  margin-bottom: calc(var(--topbar-spacer) * 2) !important;
}
.mb-3 {
  margin-bottom: calc(var(--topbar-spacer) * 3) !important;
}
.mb-4 {
  margin-bottom: calc(var(--topbar-spacer) * 4) !important;
}
.mb-5 {
  margin-bottom: calc(var(--topbar-spacer) * 5) !important;
}
.mb-10 {
  margin-bottom: calc(var(--topbar-spacer) * 10) !important;
}

.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: var(--topbar-spacer) !important;
}
.p-2 {
  padding: calc(var(--topbar-spacer) * 2) !important;
}
.p-3 {
  padding: calc(var(--topbar-spacer) * 3) !important;
}
.p-4 {
  padding: calc(var(--topbar-spacer) * 4) !important;
}
.p-5 {
  padding: calc(var(--topbar-spacer) * 5) !important;
}
.p-10 {
  padding: calc(var(--topbar-spacer) * 10) !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.px-1 {
  padding-left: var(--topbar-spacer) !important;
  padding-right: var(--topbar-spacer) !important;
}
.px-2 {
  padding-left: calc(var(--topbar-spacer) * 2) !important;
  padding-right: calc(var(--topbar-spacer) * 2) !important;
}
.px-3 {
  padding-left: calc(var(--topbar-spacer) * 3) !important;
  padding-right: calc(var(--topbar-spacer) * 3) !important;
}
.px-4 {
  padding-left: calc(var(--topbar-spacer) * 4) !important;
  padding-right: calc(var(--topbar-spacer) * 4) !important;
}
.px-5 {
  padding-left: calc(var(--topbar-spacer) * 5) !important;
  padding-right: calc(var(--topbar-spacer) * 5) !important;
}
.px-10 {
  padding-left: calc(var(--topbar-spacer) * 10) !important;
  padding-right: calc(var(--topbar-spacer) * 10) !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.py-1 {
  padding-top: var(--topbar-spacer) !important;
  padding-bottom: var(--topbar-spacer) !important;
}
.py-2 {
  padding-top: calc(var(--topbar-spacer) * 2) !important;
  padding-bottom: calc(var(--topbar-spacer) * 2) !important;
}
.py-3 {
  padding-top: calc(var(--topbar-spacer) * 3) !important;
  padding-bottom: calc(var(--topbar-spacer) * 3) !important;
}
.py-4 {
  padding-top: calc(var(--topbar-spacer) * 4) !important;
  padding-bottom: calc(var(--topbar-spacer) * 4) !important;
}
.py-5 {
  padding-top: calc(var(--topbar-spacer) * 5) !important;
  padding-bottom: calc(var(--topbar-spacer) * 5) !important;
}
.py-10 {
  padding-top: calc(var(--topbar-spacer) * 10) !important;
  padding-bottom: calc(var(--topbar-spacer) * 10) !important;
}

.pl-0 {
  padding-left: 0px !important;
}
.pl-1 {
  padding-left: var(--topbar-spacer) !important;
}
.pl-2 {
  padding-left: calc(var(--topbar-spacer) * 2) !important;
}
.pl-3 {
  padding-left: calc(var(--topbar-spacer) * 3) !important;
}
.pl-4 {
  padding-left: calc(var(--topbar-spacer) * 4) !important;
}
.pl-5 {
  padding-left: calc(var(--topbar-spacer) * 5) !important;
}
.pl-10 {
  padding-left: calc(var(--topbar-spacer) * 10) !important;
}

.pr-0 {
  padding-right: 0px !important;
}
.pr-1 {
  padding-right: var(--topbar-spacer) !important;
}
.pr-2 {
  padding-right: calc(var(--topbar-spacer) * 2) !important;
}
.pr-3 {
  padding-right: calc(var(--topbar-spacer) * 3) !important;
}
.pr-4 {
  padding-right: calc(var(--topbar-spacer) * 4) !important;
}
.pr-5 {
  padding-right: calc(var(--topbar-spacer) * 5) !important;
}
.pr-10 {
  padding-right: calc(var(--topbar-spacer) * 10) !important;
}

.pt-0 {
  padding-top: 0px !important;
}
.pt-1 {
  padding-top: var(--topbar-spacer) !important;
}
.pt-2 {
  padding-top: calc(var(--topbar-spacer) * 2) !important;
}
.pt-3 {
  padding-top: calc(var(--topbar-spacer) * 3) !important;
}
.pt-4 {
  padding-top: calc(var(--topbar-spacer) * 4) !important;
}
.pt-5 {
  padding-top: calc(var(--topbar-spacer) * 5) !important;
}
.pt-10 {
  padding-top: calc(var(--topbar-spacer) * 10) !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}
.pb-1 {
  padding-bottom: var(--topbar-spacer) !important;
}
.pb-2 {
  padding-bottom: calc(var(--topbar-spacer) * 2) !important;
}
.pb-3 {
  padding-bottom: calc(var(--topbar-spacer) * 3) !important;
}
.pb-4 {
  padding-bottom: calc(var(--topbar-spacer) * 4) !important;
}
.pb-5 {
  padding-bottom: calc(var(--topbar-spacer) * 5) !important;
}
.pb-10 {
  padding-bottom: calc(var(--topbar-spacer) * 10) !important;
}
`

export const utilities = `
.rounded-0 {
  border-radius: 0px !important;
}
.rounded-full {
  border-radius: 50% !important;
}
.rounded-pill {
  border-radius: 99999px !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
`

export const iconStyle = `
.topbar-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  height: 1em;
  vertical-align: text-bottom;
  user-select: none;
}
.topbar-icon > * {
  max-width: 100%;
  height: auto;
}
`

export const boxStyle = `
.topbar-box {
  --topbar-bg-opacity: 1;
  --topbar-text-opacity: 1;
  --topbar-border-opacity: 1;
}
`

export const btnStyle = `
.topbar-btn {
  position: relative;
  appearance: none;
  border: 1px solid transparent;
  outline: 0;
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  padding: 12px 14px;
  cursor: pointer;
  border-radius: 4px;
  text-align: center;
}
.topbar-btn.block {
  flex: 1 1 auto;
  width: 100%;
}
.topbar-btn.variant-outlined,
.topbar-btn.variant-tonal {
  --topbar-bg-opacity: 0;
}
.topbar-btn.variant-default:not(.disabled):hover {
  --topbar-bg-opacity: 0.8;
}
.topbar-btn.variant-default.click-disabled:hover {
  --topbar-bg-opacity: 0.8;
}
.topbar-btn.variant-outlined:not(.disabled):hover,
.topbar-btn.variant-tonal:not(.disabled):hover {
  --topbar-bg-opacity: 0.1;
}
.topbar-btn.variant-outlined.click-disabled:hover,
.topbar-btn.variant-tonal.click-disabled:hover {
  --topbar-bg-opacity: 0.1;
}
.topbar-btn.variant-text:not(.disabled):hover {
  --topbar-text-opacity: 0.8;
}
.topbar-btn.variant-text.click-disabled:hover {
  --topbar-text-opacity: 0.8;
}
.topbar-btn.disabled:not(.click-disabled) {
  cursor: default !important;
  pointer-events: none !important;
}
.topbar-btn.variant-default.disabled {
  --topbar-text-opacity: 0.5;
  --topbar-bg-opacity: 0.5;
}
.topbar-btn.variant-outlined.disabled {
  --topbar-text-opacity: 0.5;
  --topbar-border-opacity: 0.5;
}
.topbar-btn.variant-text.disabled,
.topbar-btn.variant-tonal.disabled {
  --topbar-text-opacity: 0.5;
}
.topbar-btn-bullet {
  position: absolute;
  box-sizing: border-box;
  right: 10px;
  bottom: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 14px;
  height: 14px;
  border-radius: 9999px;
  font-size: 8px;
  line-height: 22px;
  padding: 3px;
}
`

export const dropdownStyles = `
.theme-light.topbar-dropwdown {
  --topbar-text: var(--topbar-dark-grey);
}
.topbar-dropwdown {
  --topbar-bg-opacity: 1;
  --topbar-text-opacity: 1;
  display: inline-block;
  position: relative;
}
.topbar-dropwdown .topbar-dropwdown {
  display: block;
}
.topbar-dropwdown-container {
  position: absolute;
}
.topbar-dropwdown-container.position-top {
  bottom: 100%;
}
.topbar-dropwdown-container.position-bottom {
  top: 100%;
}
.topbar-dropwdown-container.position-left {
  right: 100%;
}
.topbar-dropwdown-container.position-right {
  left: 100%;
}
.topbar-dropwdown-container.position-top.align-start,
.topbar-dropwdown-container.position-bottom.align-start {
  left: 0;
}
.topbar-dropwdown-container.position-top.align-center,
.topbar-dropwdown-container.position-bottom.align-center {
  left: 50%;
  transform: translateX(-50%);
}
.topbar-dropwdown-container.position-top.align-end,
.topbar-dropwdown-container.position-bottom.align-end {
  right: 0;
}
.topbar-dropwdown-container.position-left.align-start,
.topbar-dropwdown-container.position-right.align-start {
  top: 0;
}
.topbar-dropwdown-container.position-left.align-center,
.topbar-dropwdown-container.position-right.align-center {
  top: 50%;
  transform: translateY(-50%);
}
.topbar-dropwdown-container.position-left.align-end,
.topbar-dropwdown-container.position-right.align-end {
  bottom: 0;
}
.topbar-dropwdown-content {
  background-color: white;
  box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.topbar-dropwdown-item {
  justify-content: flex-start;
  border-radius: 0px !important;
}
.topbar-dropwdown-item:first-child,
.topbar-dropwdown .topbar-dropwdown:first-child {
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important;
}
.topbar-dropwdown-item:last-child,
.topbar-dropwdown .topbar-dropwdown:last-child {
  border-bottom-left-radius: inherit !important;
  border-bottom-right-radius: inherit !important;
}
.topbar-dropwdown-item .prepend-icon {
  margin-right: calc(var(--topbar-spacer) * 4);
}
.topbar-dropwdown-item .append-icon {
  margin-left: auto;
}
.topbar-dropwdown .topbar-dropwdown:not(:first-child),
.topbar-dropwdown .topbar-dropwdown-item:not(:first-child) {
  boder-top: 1px solid rgba(var(--topbar-border), 1);
}
.topbar-dropwdown .topbar-dropwdown.active > .topbar-dropwdown-item {
  --topbar-bg-opacity: 0.1;
}
`

export const topbarMessage = `
.topbar-message {
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4900;
}

.fade-appear {
  opacity: 0;
}
.fade-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

.fade-text-appear {
  opacity: 0;
  max-width: 0 !important;
  white-space: nowrap;
}
.fade-text-appear-active {
  opacity: 1;
  max-width: 20% !important;
  white-space: nowrap;
  transition: opacity 500ms ease-in, max-width 500ms ease-in;
}
.fade-text-enter {
  opacity: 0;
  max-width: 0 !important;
  white-space: nowrap;
}
.fade-text-enter-active {
  opacity: 1;
  max-width: 20% !important;
  transition: opacity 500ms ease-in, max-width 500ms ease-in;
}
.fade-text-exit {
  opacity: 1;
  max-width: 20% !important;
  white-space: nowrap;
}
.fade-text-exit-active {
  opacity: 0;
  max-width: 0 !important;
  white-space: nowrap;
  transition: opacity 500ms ease-in, max-width 500ms ease-out;
}

.slide-bottom-enter {
  opacity: 0;
  transform: translateY(-100%);
}
.slide-bottom-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}
.slide-bottom-exit {
  opacity: 1;
  transform: translateY(0%);
}
.slide-bottom-exit-active {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity 500ms ease-in, transform 500ms ease-in;
}
`